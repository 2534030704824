<div class="shared-lists">
	<Header route="projects" />

	<div class="lists">
		{#if sharedLists.length}
		{#each sharedLists as list}
		<div class="list">
			<div class="header">
				<h2>{list.title}</h2>
				<div class="header-side">
					<strong>Created by</strong> <span class="user">{user(list.user)}</span> <strong>on</strong> {time(list.timestamp)}
				</div>
			</div>

			<!-- <p class="dida">Number of projects: {list.projects.length}</p> -->
			<div class="projects">
				{#each list.projects as project}
				<div class="project">
					<Thumbnail {project} />
					<p>{project.client} / {project.title}</p>
				</div>
				{/each}
			</div>
			<div class="footer">
				<Button on:tap="remove(list.id)">Remove</Button>
				<div class="link">
					<strong>Shared on:</strong><br> <a href="https://catalog.thevisualagency.com/client/{list.id}" target="_blank">catalog.thevisualagency.com/client/{list.id}</a>
				</div>

			</div>

		</div>
		{/each}
		{:else}
		<div class="message">
			Sorry, but there are no shared lists at the moment<br>
			<span>please use the add button below the project thumbnail to create your first shared list!</span>
		</div>
		{/if}
	</div>
</div>

<style type="text/postcss">
  @import "../lib/css/properties.css";

  .shared-lists {
    width: 100%;
  }
  .lists {
    width: 75%;
    min-width: 800px;
    min-height: calc(100vh - 66px);
    margin: 0 auto;
    margin-top: 64px;
    padding: 1px 0;
  }
  .list {
    margin: 2rem 0;
    padding: 0.75rem;
    border: 1px solid #cccccc;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 0.75rem;
  }
  .header h2 {
    margin: 0;
  }
  .header-side {
    font-size: 13px;
  }
  .header-side .user {
    text-transform: capitalize;
  }
  .dida {
    font-size: 13px;
    padding: 0.75rem;
    padding-bottom: 0;
    margin: 0;
  }
  .projects {
    display: flex;
    overflow: auto;
    margin-bottom: 22px;
  }
  .project {
    width: 20%;
    min-width: 140px;
    padding: 0.75rem;
    box-sizing: border-box;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    padding-top: 0;
  }
  .footer .link {
    text-align: right;
  }
  .footer .link strong {
    font-size: 13px;
  }
  .message {
    text-align: center;
    flex-direction: column;
  }
  .message span {
    font-size: 1rem;
    max-width: 320px;
    color: var(--black);
    margin-top: 1rem;
  }
</style>

<script>
  import { push } from "svelte-extras";
  import _ from "lodash";
  import moment from "moment";

  import Header from "../ui/Header.html";
  import Thumbnail from "../ui/Thumbnail.html";
  import Button from "../ui/Button.html";

  export default {
    oncreate() {
      let sharedLists = [];
      const { database } = this.store.get();

      var query = database.ref(`shared`);
      query.on("value", snapshot => {
        let db = snapshot.val();

        _.forIn(db, (value, key) => {
          sharedLists.push({
            id: key,
            timestamp: value.timestamp,
            title: value.title,
            user: value.user,
            projects: value.shareList
          });
        });

        sharedLists.sort((a, b) => {
          return b.timestamp - a.timestamp;
        });

        this.set({ sharedLists });
      });
    },
    helpers: {
      time(timestamp) {
        return moment(timestamp).format("MMMM Do YYYY, h:mm a");
      },
      user(email) {
        return email.replace("@thevisualagency.com", "").replace(".", " ");
      }
    },
    methods: {
      remove(id) {
        const { database } = this.store.get();
        database.ref(`shared/${id}`).remove();
      },
      push
    },
    data() {
      return {
        sharedLists: []
      };
    },
    components: {
      Header,
      Thumbnail,
      Button
    }
  };
</script>
