<header>
  <div>The Visual Agency</div>
  <a href="https://thevisualagency.com/it/contatti/" target="_blank"
    >Contact Us</a
  >
</header>

<main>
  {#if projects && projects.length}
  <div class="projects">
    {#each projects as project}
    <div class="project">
      <div class="thumb">
        <Thumbnail {project} />
      </div>
      <div class="content">
        <h2>{project.client} / {project.title}</h2>
        <div class="links">
          {#if project.links} {#each project.links as link}
          <div>
            <a class="link" href="{formatLink(link.url)}" target="_blank"
              >{link.title !== '' ? link.title : link.url}</a
            >
          </div>
          {/each} {/if}
        </div>
      </div>
    </div>
    {/each}
  </div>
  {:elseif !error}
  <div class="loader">
    <img src="/assets/images/empty.gif" alt="loader" />
  </div>
  {:else}
  <div class="message">Sorry, but the link is no longer valid</div>
  {/if}
</main>

<style type="text/postcss">
  @import "../lib/css/properties.css";
  header {
    box-sizing: border-box;
    border-bottom: 1px solid var(--light-grey);
    background-color: var(--white);
    display: flex;
    padding: 0 calc(1.5rem - 1px) 0 1.5rem;
    height: 64px;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
  }
  main {
    width: 75%;
    min-width: 800px;
    margin: 0 auto;
    min-height: calc(100vh - 66px);
    margin-top: 64px;
    padding: 1px 0;
  }
  .project {
    border: 1px solid #cccccc;
    padding: 0.75rem;
    margin: 1rem 0;
    display: flex;
  }
  .thumb {
    width: 20%;
  }
  .content {
    margin-left: 1.5rem;
  }
  .content h2 {
    margin-top: 0;
  }
  .links {
    display: flex;
    flex-direction: column;
  }
  .link {
    font-size: 0.825rem;
    margin: 0 0.5rem 0.5rem 0;
    padding: 0.5em;
    display: inline-block;
    border: 1px solid var(--blue);
    border-radius: 4px;
    transition: all 0.25s;
    pointer-events: auto;
    color: var(--blue);
    text-decoration: none;
  }
  .link:hover {
    background-color: var(--blue);
    color: var(--white);
  }
  .loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader img {
    max-width: 350px;
  }
</style>

<script>
  import { push } from "svelte-extras";

  import Thumbnail from "../ui/Thumbnail.html";

  export default {
    oncreate() {
      const { database } = this.store.get();
      const { match } = this.get();
      const id = match.params.id;

      var query = database.ref(`shared/${id}/shareList`);
      query.on("value", (snapshot) => {
        this.set({
          error: snapshot.exists() ? false : true,
          projects: [],
        });

        snapshot.forEach((childSnapshot) => {
          this.push("projects", childSnapshot.val());
        });
      });
    },
    methods: { push },
    helpers: {
      formatLink(link) {
        return link.replaceAll(/\\_/g, "_");
      },
    },
    data() {
      return {
        projects: [],
        error: false,
      };
    },
    components: {
      Thumbnail,
    },
  };
</script>
