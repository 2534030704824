{#if removable}
  <div class="tag removable">
    {tag}
    <div class="tag-close" on:click="fire('tap', event)">+</div>
  </div>
{:else}
  <div class="tag {selected ? 'selected' : ''}" on:click="toggleTag(tag)" >
    {tag}
  </div>
{/if}

<style type="text/postcss">
  @import '../lib/css/properties.css';

  .tag {
    background-color: #fff;
    border: 2px solid var(--orange);
    color: var(--orange);

    font-size: .825rem;
    font-weight: bold;

    cursor: pointer;
    display: inline-block;

    border-radius: 24px;
    height: 24px;
    line-height: 24px;

    position: relative;
    padding: 0 .75rem;
    margin: .25rem;

    transition: all .25s;

    user-select: none;
  }


  .tag.removable,
  .tag:hover {
    background-color: var(--orange);
    color: var(--white);
  }

  .tag.removable {
    padding-right: 30px;
  }

  .tag.selected {
    background-color: var(--black);
    border-color: var(--black);
    color: var(--white);
  }

  .tag-close {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: .14rem;
    margin-top: -10px;

    background-color: transparent;
    cursor: pointer;
    color: var(--white);
    border: 1px solid var(--white);
    padding: 0;
    border-radius: 20px;
    outline: none;
    width: 20px;
    height: 20px;
    line-height: 17px;
    font-size: 16px;
    text-align: center;
    transform: rotate(45deg);

    transition: all .25s;
  }

  .tag-close:hover {
    background-color: var(--white);
    color: var(--orange);
  }
</style>

<script>
  import { push, splice } from 'svelte-extras';
  import _ from 'lodash';

  export default {
    onupdate({changed, current}) {
      if (changed.tagsSelected) {
        if (current.tagsSelected.indexOf(current.tag) >= 0) {
          this.set({selected: true});
        } else {
          this.set({selected: false});
        }
      }
    },
    methods: {
      toggleTag(tag) {
        let {tagsSelected} = this.get();
        let index = _.indexOf(tagsSelected, tag);

        if (index >= 0) {
          this.splice('tagsSelected', index, 1);
          this.set({selected: false});
        } else {
          this.push('tagsSelected', tag);
          this.set({selected: true});
        }
      },
      push, splice
    },
    data() {
      return {
        removable: false,
        tag: '',
        selected: false
      }
    }
  }
</script>
