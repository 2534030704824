import _ from 'lodash';

function resetQuery() {
  let {
    query
  } = this.get();

  _.forIn(query, (value, key) => {
    if (typeof value === 'string') {
      query[key] = '';
    } else if (Array.isArray(value)) {
      query[key] = [];
    }
  });

  this.set({
    query
  });
}

export {resetQuery};
