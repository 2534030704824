{#if type === 'button'}
  <button type="button" on:click="fire('tap', event)" class="button {bold ? 'button-bold': ''}" disabled={disabled ? 'true' : ''}>
    <slot></slot>
  </button>
{:elseif type === 'submit'}
  <button type="submit" on:click="fire('tap', event)" class="button {bold ? 'button-bold': ''}" disabled={disabled ? 'true' : ''}>
    <slot></slot>
  </button>
{/if}


<style type="text/postcss">
  @import '../lib/css/properties.css';

  .button {
    font-weight: bold;
    font-size: .9rem;
    line-height: 25px;
    text-align: center;
    text-decoration: none;

    color: var(--orange);
    background-color: transparent;
    border: 2px solid var(--orange);
    border-radius: 4px;

    outline: none;
    user-select: none;
    cursor: pointer;

    min-width: 6rem;
    padding: .25rem 1.5rem;
    overflow: hidden;
    position: relative;
    margin: 0;

    transition: all .25s linear;
  }

  .button:hover {
    color: var(--white);
    background-color: var(--orange);
  }

  .button-bold {
    color: var(--white);
    background-color: var(--orange);
    transition: all .25s linear;
  }

  .button-bold:hover {
    color: var(--orange);
    background-color: var(--white);
  }

  .button:disabled {
    color: var(--light-gray);
    border-color: var(--light-gray);
    cursor: not-allowed;
  }

  .button:disabled,
  .button:disabled:hover {
    border-color: var(--light-grey);
    background-color: color(var(--dark-grey) alpha(-90%));
    color: var(--light-grey);
  }
</style>

<script>
  export default {
    data() {
      return {
        disabled: false,
        bold: false,
        type: 'button'
      }
    }
  }
</script>
