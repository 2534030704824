<div class="project-detail">
  <div class="thumb-container">
    <div class="thumb">
      {#if !imageReady}
      <div class="thumb-placeholder"></div>
      {/if}
      <img class="thumb-image {imageReady ? 'visible': ''}" src="{src}" alt="{project.client} / {project.title}">
    </div>
  </div>


  <div class="content">
    <header class="header">
      <h1 class="client">{project.client}</h1>
      <h2 class="title">{project.title}</h2>

      <div class="description">
        {project.description}
      </div>
      {#if project.descriptionEn}
      <div class="description">
        {project.descriptionEn}
      </div>
      {/if}
    </header>

    {#if project.links && project.links.length}
    <div class="detail">
      <h3 class="section-title">Links</h3>
      <ul class="detail-list">
        {#each project.links as link}
        <a class="link" href="{formatLink(link.url)}" target="_blank">{link.title !== '' ? link.title : link.url}</a>
        {/each}
      </ul>
    </div>
    {/if}

    <!-- Date -->
    {#if project.from}
    <div class="detail">
      <h3 class="section-title">Date</h3>
      <div class="dates-container">
        <div class="dates">
          <div class="date">
            <div>{project.from}</div>
          </div>
          {#if project.from !== project.to}
          <div class="date-symbol">&#8594;</div>
          <div class="date">
            <div>{project.to}</div>
          </div>
          {/if}
        </div>
      </div>
    </div>
    {/if}

    <!-- Details -->
    {#each searchFields as field}
    {#if project[field.key] && project[field.key].length && field.detail}
    <div class="detail">
      <h3 class="section-title">{field.label}</h3>
      <ul class="detail-list">
        {#if field.detail === 'single'}
        <Tag tag="{project[field.key]}" selected="{true}" />
        {:else}
        {#each project[field.key] as item}
        {#if field.key === 'authors'}
        <li>{item}</li>
        {:else}
        <Tag tag="{item}" selected="{true}" />
        {/if}
        {/each}
        {/if}
        </ul>
        </div>
        {/if}
    {/each}

    <!-- Notes -->
    {#if project.note != undefined}
    <div class="detail">
      <h3 class="section-title">Notes</h3>
      <div class="section-content">
        {project.note}
      </div>
    </div>
    {/if}

    <div class="actions">
      <div class="actions-container">
        <Button on:tap="generateSlide(project)" bold={true}>Generate PPT (beta)</Button>
        <Button on:tap="fire('closeProjectDetail')" bold={true}>Close</Button>
      </div>
    </div>
  </div>

</div>


<style type="text/postcss">
  @import '../lib/css/properties.css';

  .project-detail {
    color: var(--dark-grey);
    padding: 64px 1.5rem 100px;
    width: calc(100% - 3rem);
    display: flex;
    align-items: flex-start;
    background: #fff;
  }

  .thumb-container {
    width: calc(50% - 1.5rem);
    margin-right: 1.5rem;
    display: flex;
    justify-content: flex-end;
  }

  .thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    flex-direction: column;
  }

  .thumb-image {
    width: 100%;
    margin: 0;
    opacity: 0;
    height: 0;
    /* border: 2px solid var(--super-light-grey); */
    box-shadow: 0 0 10px 2px rgba(0,0,0,.05);
    transition: opacity .5s;
  }

  .thumb-image.visible {
    opacity: 1;
    height: auto;
  }

  .thumb-placeholder {
    width: 100%;
    padding-bottom: 66%;
    background: linear-gradient(270deg, var(--dark-white), var(--super-light-grey));
    background-size: 400% 400%;
    animation: loading 1.5s ease infinite;
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: calc(50% - 1.5rem);
    margin-left: 1.5rem;
    flex-direction: column;
  }

  .header {
    width: 100%;
    text-align: left;
  }

  .client {
    margin: 0 .0 .25rem 0;
    font-size: 1.125rem;
    font-weight: normal;
    color: var(--orange);
    text-transform: uppercase;
  }

  .client:empty {
    width: 20%;
    height: 1.125rem;
  }

  .title {
    font-size: 2rem;
    margin: 0 0 .5rem 0;
    font-weight: bold;
  }

  .title:empty {
    width: 30%;
    height: 2rem;
  }

  .description {
    line-height: 1.5;
  }

  .section-title {
    font-size: .825rem;
    margin: 2rem 0 .5rem 0;
    padding-bottom: .5rem;
    border-bottom: 1px solid var(--super-light-grey);
    text-transform: capitalize;
  }

  .detail {
    width: 60%;
  }

  .detail-list {
    list-style-type: none;
    padding: 0;
    pointer-events: none;
  }

  .link {
    font-size: .825rem;
    margin: 0 .5rem .5rem 0;
    padding: .5em;
    display: inline-block;
    border: 1px solid var(--blue);
    border-radius: 4px;
    transition: all .25s;
    pointer-events: auto;
  }

  .link:hover {
    background-color: var(--blue);
  }

  .link {
    color: var(--blue);
    text-decoration: none;
  }

  .link:hover {
    color: var(--white);
  }

  .dates-container,
  .dates {
    display: flex;
    justify-content: space-between;
  }

  .date-symbol {
    display: flex;
    align-items: center;
    width: 30px;
    justify-content: center;
  }

  .actions {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: var(--white);
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }

  .actions-container {
    width: calc(100% - 3rem);
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    border-top: solid 1px var(--light-grey);
  }

  
  *:empty {
    background: linear-gradient(270deg, var(--dark-white), var(--super-light-grey));
    background-size: 400% 400%;
    animation: loading 1.5s ease infinite;
  }

  @keyframes loading { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
</style>

<script>
  import slug from 'speakingurl';

  // Components
  import Button from '../ui/Button.html';
  import Tag from '../ui/Tag.html';

  export default {
                onupdate({ changed, current }) {
      if (changed.project) {
        console.log(current.project)
        this.set({ imageReady: false, src: '' });

        const findUrl = () => {
          if (current.project.thumbnailUrl) {
            return current.project.thumbnailUrl
          } else {
            const url = "https://interactive.thevisualagency.com/assets/catalog/thumbs-new/";
            const thumb = `${url}${slug(current.project.client)}_${slug(
              current.project.title
            )}.jpg`;
            return thumb;
          }
        }

        const img = new Image();
        const url = 'https://interactive.thevisualagency.com/assets/catalog/thumbs-new/';
        const thumb = findUrl();

        img.src = thumb;
        img.onload = () => {
          this.set({
            imageReady: true,
            src: thumb
          });
        };
      }
    },
    helpers: {
      formatLink(link) {
        return link.replaceAll(/\\_/g, '_')
      }
    },
    data() {
      return {
        imageReady: false,
        src: ''
      }
    },
                methods: {
                  generateSlide: function (d) {
                    const url = `https://interactive.thevisualagency.com/test/tva-catalog-ppt?id=${d.airtableId}`

                    window.open(url, '_blank')
                  }
                },
    components: {
      Button,
      Tag
    }
  }
</script>
