<div class="project" on:click="fire('selectProject', project)">

	<div class="thumb-wrapper {imageReady ? '': 'empty'}">
		{#if project.isPrivate === 'yes'}
		<div class="private-badge">
			<img src="/assets/images/private.svg" alt="Private">
		</div>
		{/if}

		{#if project.status}
		<div class="status-badge">
			{#if project.status === 'progress'}
			<img src="/assets/images/progress.svg" alt="Progress">
			{:elseif project.status === 'dead'}
			<img src="/assets/images/dead.svg" alt="dead">
			{:else}
			<!-- TODO: add closed badge image -->
			{/if}
		</div>
		{/if}
		<div class="thumb-container {imageReady ? 'visible':''}">
			<img class="thumb" src="{src}" alt="{project.client} - {project.title} preview">
		</div>
	</div>

</div>

<style type="text/postcss">
  @import "../lib/css/properties.css";

  .thumb-wrapper {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding-bottom: calc(100% / 16 * 12);
    overflow: hidden;
    background: none;
    display: flex;
    justify-content: center;
    align-content: center;
    background: linear-gradient(
      270deg,
      var(--dark-white),
      var(--super-light-grey)
    );
    background-size: 400% 400%;
    animation: loading 1.5s ease infinite;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);

    transition: all 2s ease;
  }

  .thumb-wrapper.empty {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0);
  }

  .status-badge,
  .private-badge {
    position: absolute;
    z-index: 150;
  }

  .status-badge img,
  .private-badge img {
    max-width: 100%;
  }

  .private-badge {
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    animation: 1s fadeIn;
  }

  .status-badge {
    width: 30px;
    height: 30px;
    top: 4px;
    right: 4px;
    animation: 1s fadeIn;
  }

  .thumb-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    opacity: 0;
  }

  .thumb-container.visible {
    animation: fadeIn 0.8s;
    animation-fill-mode: forwards;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .thumb-container .thumb {
    height: 100%;
  }

  @keyframes loading {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>

<script>
  import slug from "speakingurl";

  export default {
    onupdate({ changed, current }) {
      if (changed.project) {
        this.set({ imageReady: false, src: "" });

        const findUrl = () => {
          if (current.project.thumbnailUrl) {
            return current.project.thumbnailUrl
          } else {
            const url = "https://interactive.thevisualagency.com/assets/catalog/thumbs-new/";
            const thumb = `${url}${slug(current.project.client)}_${slug(
              current.project.title
            )}.jpg`;
            return thumb;
          }
        }

        const img = new Image();
        const thumb = findUrl();

        img.src = thumb;
        img.onload = () => {
          this.set({
            imageReady: true,
            src: thumb
          });
        };
      }
    },
    data() {
      return {
        imageReady: false,
        src: ""
      };
    }
  };
</script>
