<div class="header">
  <a class="title" href="/projects" use:link on:click="resetQuery()">
    <h1 class="owner">The Visual Agency </h1>
    <h2 class="product">Catalog</h2>
    <p class="version">{version}</p>
    <!-- <p class="data-update"><span>Data Updated on: </span>{updated}</p> -->
  </a>

  <div class="actions">
    {#if route}
      <NavLink to="/{route}">{route}</NavLink>
    {/if}
    {#if user }
      <button class="signout" on:click="signOut()">Sign out</button>
    {/if}
  </div>
</div>

<style type="text/postcss">
  @import '../lib/css/properties.css';

  .header {
    box-sizing: border-box;
    border-bottom: 1px solid var(--light-grey);
    background-color: var(--white);
    display: flex;
    padding: 0 calc(1.5rem - 1px) 0 1.5rem;
    height: 64px;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: inherit;
    z-index: 200;
  }

  .title {
    cursor: pointer;
    color: var(--black);
    text-decoration: none;
  }

  .owner,
  .product {
    display: inline;
    font-size: 1rem;
  }

  .owner {
    color: var(--orange);
    font-weight: normal;
  }

  .product {
    color: var(--black);
  }
  .version,
  .data-update {
    display: inline;
    font-size: 13px;
    margin: 0;
    padding-left: 7px;
    margin-left: 4px;
    border-left: 1px solid var(--light-grey);

    & span {
      font-weight: 700;
    }
  }

  .actions {
    text-align: right;
  }

  .signout {
    background-color: transparent;
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-size: .825rem;
    color: var(--orange);
    padding: 2px 28px 2px 0;
    text-decoration: none;
    margin-left: 1.5rem;
    background-image: url('/assets/images/logout.svg');
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: right center;
    outline: none;
    cursor: pointer;
  }
  .signout:hover {
    text-decoration: underline;
  }
</style>

<script>
  import moment from 'moment';
  import _ from 'lodash';
  import NavLink from 'svelte-routing/NavLink.html';
  import link from 'svelte-routing/link';

  import { resetQuery } from '../../lib/js/utils.js';

  import pkg from '../../../../package.json';
  import timestamp from '../../config/timestamp.json';

  export default {
    oncreate() {
      this.set({
        version: `v ${pkg.version}`,
        updated: moment(timestamp.timestamp).format('MMMM Do YYYY, h:mm a')
      });

      let { ga, fb } = this.store.get();

      fb.auth().onAuthStateChanged(user => {
        if (user) {
          ga.send('event', { userId: user.email });
          this.set({ user });
        }
      });
    },
    data() {
      return {
        user: null,
        full: false,
        version: '',
        updated: ''
      }
    },
    methods: {
      resetQuery,
      signOut: function() {
        const { fb, history } = this.store.get();

        fb.auth().signOut().then(function () {
          // Sign-out successful.
          history.push('/login');
        }).catch(function (error) {
          // An error happened.
        });
      }
    },
    components: {
      NavLink
    },
    actions: { link }
  };
</script>
