// Polyfills
import 'core-js';
import 'html5-history-api';

// Utilities
import { Store } from 'svelte/store.js';
import { createBrowserHistory } from 'svelte-routing';

// Services
import GAnalytics from 'ganalytics';
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';

// Config
import firebaseConfig from './config/firebase.config';
import anlayticsConfig from './config/analytics.config';

// Components
import App from './components/App.html';


// Services initialization
const fb = firebase.initializeApp(firebaseConfig);
const ga = new GAnalytics(anlayticsConfig.trackingId);
const database = firebase.database();

// Get env from Rollup
const env = 'ENVIRONMENT';


// Initialize routing history
const history = createBrowserHistory();

const store = new Store({
  history,
  env,
  fb,
  ga,
  database
});

new App({
  target: document.querySelector('#app'),
  store,
  data: {
    history,
    env,
    fb,
    ga,
    database
  }
});
