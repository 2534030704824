// Initialize Firebase
// TODO: Replace with your project's customized code snippet

const config = {
  apiKey: 'AIzaSyDtgkNtI6GlyT1GR3mPKGUA0KPHd1hpTt0',
  authDomain: 'catalog-f33ee.firebaseapp.com',
  databaseURL: 'https://catalog-f33ee.firebaseio.com',
  storageBucket: 'catalog-f33ee.appspot.com',
  messagingSenderId: '544817973908',
  projectId: 'catalog-f33ee'
};

export default config;
