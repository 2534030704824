<svelte:window on:keydown="keyDown(event.keyCode)"/>

<div class="filtering-tags-field">

  <div class="field">
    <input ref:input class="input input-text input-text-filtering" on:focus="set({added: false})" on:keyup="set({added: false})" bind:value="newTag" type="text" placeholder="{placeholder}">
    {#if results && results.length && newTag !== '' && !added}
      <ul class="results" ref:results>
        {#each results as result, index}
          <li class="suggestion {suggestionSelected === index ? 'selected' : ''}" on:click="addTag(result)">{result}</li>
        {/each}
      </ul>
    {/if}
  </div>

  {#if tagsSelected && tagsSelected.length}
    <div class="tags">
      {#if typeof tagsSelected !== 'string' }
        {#each tagsSelected as tag, tagIndex} 
          <Tag removable=true on:tap="removeTag(tagIndex)" tag={tag} />
        {/each}
      {:else}
        <Tag removable=true on:tap="removeTag(tagIndex)" tag={tagsSelected} />
      {/if}
    </div>
  {/if}
</div>

<style type="text/postcss">
  @import '../lib/css/properties.css';

  .filtering-tags-field {
    width: 100%;
    border-top: 1px solid var(--light-grey);
  }

  .field {
    position: relative;
  }

  .input {
    box-sizing: border-box;
    background-image: url('/assets/images/lens.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: .75rem 50%;
    background-color: transparent;

    border: 0;
    height: 65px;
    width: 100%;
    padding-left: 2.75rem;
    padding-right: 2rem;
    color: var(--orange);
    border-radius: 0;
    border-bottom: 0;
    width: 100%;

  }

  .input:focus {
    outline: none;
    border: 0;
  }

  .input::placeholder {
    text-transform: capitalize;
    color: var(--dark-grey);
  }

  .results  {
    width: 100%;
    position: absolute;
    top: 65px;
    left: 0;
    
    margin: 0;
    height: 150px;
    overflow-y: scroll;

    background-color: var(--white);
    border-top: 1px solid var(--light-grey);
    border-bottom: 1px solid var(--light-grey);

    list-style-type: none;
    padding: 0;
    z-index: 1000;
  }

  .suggestion {
    cursor: pointer;
    padding: .75rem;
  }

  .suggestion.selected,
  .suggestion:hover {
    background-color: var(--super-light-grey);
  }

  .tags {
    padding: .5rem 0;
    border-top: 1px solid var(--super-light-grey);
  }

</style>

<script> 
  import { push, splice, observe, setDeep } from 'svelte-extras';
  import _ from 'lodash';
  
  import indexOf from 'lodash/indexOf';

  // Components
  import Tag from '../ui/Tag.html';

  export default {
    oncreate() {
      let {query, tagSet} = this.get();
      this.set({tagsSelected: query[tagSet.key]});
    },
    data() {
      return {
        newTag: '',
        tagsSelected: [],
        added: false,
        tagIndex: null,
        borderless: true,
        keyCode: '',
        suggestionSelected: null,
        suggestionRange: [0,3]
      }
    },
    onupdate({changed, current}) {
      if (changed.added && !current.added)
        this.set({suggestionSelected: 0});

      if (changed.tagsSelected) {
        let query = current.tagsSelected.slice();
        this.setDeep(`query.${current.tagSet.key}`, query);
      }

      if (changed.query) {
        this.set({tagsSelected: current.query[current.tagSet.key]});
      }
    },
    computed: {
      options: ({tagSet}) => tagSet.values,
      results: ({ newTag, options }) => {
        if (options.length) {
          return options.filter(value => {
            if (newTag && value) {
              const re = new RegExp(`^${newTag}`, 'i');
              return value.match(re);
            }
          });
        }
      }
    },
    methods: {
      push,
      splice,
      observe,
      setDeep,
      addTag: function(newTag) {
        let { tagsSelected } = this.get();

        // Filter empty tags
        if (newTag !== '' && indexOf(tagsSelected, newTag) === -1 && tagsSelected.length < 4) {
          this.push('tagsSelected', newTag);
        }

        // Visually clear the field
        this.set({ newTag: '' });
        this.refs.input.value = '';
        this.refs.input.blur();

        this.set({ added: true });
      },
      removeTag: function(i) {
        this.splice('tagsSelected', i, 1);
      },
      keyDown: function(keyCode) {
        let {results, suggestionSelected, suggestionRange, newTag} = this.get();
        let dropdown = this.refs.results;

        if (results.length && keyCode) {
          let itemHeight = dropdown.childNodes[0].getBoundingClientRect().height;

          if (keyCode === 40 && (!suggestionSelected || suggestionSelected < results.length - 1)) {
            this.set({suggestionSelected: suggestionSelected + 1, suggestionRange: [suggestionRange[0] + 1, suggestionRange[1] + 1]});
            
            if (!(suggestionSelected in suggestionRange)) {
              dropdown.scrollTop = dropdown.scrollTop + itemHeight;
            }
          } else if (keyCode === 38 && suggestionSelected !== 0) {
            this.set({suggestionSelected: suggestionSelected - 1, suggestionRange: [suggestionRange[0] - 1, suggestionRange[1] - 1]});
            
            if (!(suggestionSelected in suggestionRange)) {
              dropdown.scrollTop = dropdown.scrollTop - itemHeight;
            }
          } else if (keyCode === 13 && suggestionSelected && results[suggestionSelected]) {
            this.addTag(results[suggestionSelected]);
            this.set({added: true});
          } else if (keyCode === 27 && newTag) {
            this.set({added: true, newTag: ''});
          }
        }
      }
    },
    components: {
      Tag
    }
  }
</script>
