{#if projectsCount === 0}
<div class="message">
	Sorry, but there are no projects to show with this search criteria :(
</div>
{:elseif projectsCount > 0}

<div class="projects-list {projects && projects.length ? '' : 'projects-list-empty'}">
	{#each projectsFiltered as project}
	<div class="project">
		<Thumbnail {project} on:selectProject="fire('selectProject', event)" />
		<div class="details {project.client || project.title ? 'visible' : ''}" title="{project.client} / {project.title}">
			{#if project.client || project.title}
			<p>{@html highlight(project.client, textSelected)} / {@html highlight(project.title, textSelected)}</p>
			{/if}

			<div class="button-container">
				{#if project.isPrivate !== 'yes'}
				<button class="button-small {shareList.indexOf(project) >= 0 ? 'active button-small_remove' : 'button-small_add'}"
				 on:click="addToShareList(project)"></button>
				{/if}
			</div>

		</div>
	</div>
	{/each}
</div>
{/if}

<style type="text/postcss">
  @import "../lib/css/properties.css";

  .projects-list {
    padding: 0;
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 0 0.75rem;
    box-sizing: border-box;
  }
  .project {
    width: calc(100% / 4);
    box-sizing: border-box;
    padding: 0.75rem;
    position: relative;

    display: flex;
    flex-direction: column;

    cursor: pointer;
  }

  .project:hover .details p {
    color: var(--orange);
  }
  .details {
    width: 100%;
    height: 1.5rem;
    margin-top: 0.5rem;
    display: flex;

    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    background: linear-gradient(
      270deg,
      var(--dark-white),
      var(--super-light-grey)
    );
    background-size: 400% 400%;
    animation: loading 1.5s ease infinite 0.15s;
  }
  .details p {
    width: calc(100% - 0.5rem - 20px);
    height: 100%;
    background: #fff;
    margin: 0;
    color: var(--black);
    animation: 1s fadeIn;
    overflow: hidden;
  }
  :global(.details p .search-text-highlighted) {
    background-color: color(var(--blue) tint(70%));
  }
  .button-container {
    padding-left: 0.5rem;
    background: #fff;
    width: 20px;
    height: 100%;
  }
</style>

<script>
  import Thumbnail from "../ui/Thumbnail.html";

  import { push, splice } from "svelte-extras";

  export default {
    computed: {
      projectsFiltered: ({ projects, projectRange }) =>
        projectRange ? projects.slice(projectRange[0] - 1, projectRange[1]) : []
    },
    methods: {
      addToShareList: function(project) {
        let { shareList, selected } = this.get();
        let index = shareList.indexOf(project);

        if (index >= 0) {
          this.splice("shareList", index, 1);
        } else {
          this.push("shareList", project);
        }
      },
      push,
      splice
    },
    helpers: {
      highlight(string, textSelected) {
        const regex = new RegExp(textSelected, "i");
        const match = string.match(regex);
        return string.replace(
          regex,
          `<span class="search-text-highlighted">${match}</span>`
        );
      }
    },
    data() {
      return {
        selected: false
      };
    },
    components: {
      Thumbnail
    }
  };
</script>
