{#if $data}
  <div class="main">

    {#if !selectedProject}
      <div class="projects">
        <Header bind:query route="shared"/>
        {#if projectsCount !== null}
          <div class="projects-pagination-list" style="margin-bottom: {shareListHeight}px">
            <ProjectsPagination :projectsCount :projectsPerPage />
            <ProjectsList :projectRange :projects :projectsCount on:selectProject="selectProject(event)" bind:shareList textSelected="{query.freeText}"/>
          </div>
        {/if}
        {#if shareList.length}
          <div class="share-list" transition:slide bind:offsetHeight=shareListHeight>
            <ShareList bind:shareList />
          </div>
        {/if}
      </div>
    {:else}
      <div class="project-detail">
        <Header />
        <ProjectDetail on:closeProjectDetail="closeProjectsDetail()" project="{selectedProject}" :searchFields />
      </div>
    {/if}

    {#if !selectedProject}
      <div class="actions">
        <ProjectsSearch bind:query :searchFields/>

        <!-- <div class="authoring">
          <div class="update-projects">
            {#if $token && $options}
              <Sheets />
            {/if}
          </div>
        </div> -->
      </div>
    {/if}
  </div>
{:else}
  <div class="loader">
    <img src="/assets/images/empty.gif" alt="loader">
  </div>
{/if}

<style type="text/postcss">
  @import '../lib/css/properties.css';

  .main {
    display: flex;
  }
  .projects {
    width: 75%;
    box-sizing: border-box;
  }
  .project-detail {
    width: 100%;
    height: 100vh;
    padding: 1.5rem 0;
    box-sizing: border-box;
  }
  .projects-pagination-list {
    padding-top: 64px;
  }
  .actions {
    border-left: 1px solid var(--light-grey);
    width: 25%;
    min-height: 100vh;
    box-sizing: border-box;
    z-index: 300;
  }

  .share-list {
    position: fixed;
    width: 75%;
    box-sizing: border-box;
    background: white;
    left: 0;
    bottom: 0;
    z-index: 300;
  }

  /* .authoring {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 25%;
    z-index: 200;
  } */

  .loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader img {
    max-width: 350px;
  }

</style>

<script>
  import _ from 'lodash';
  import {slide} from 'svelte-transitions';
  import moment from 'moment';
  // UI
  import Header from '../ui/Header.html';

  // Projects
  import ProjectsList from '../projects/ProjectsList.html';
  import ProjectsPagination from '../projects/ProjectsPagination.html';
  import ProjectsSearch from '../projects/ProjectsSearch.html';
  import ProjectDetail from '../projects/ProjectDetail.html';
  import ShareList from '../share/ShareList.html';

  // Services
  import Sheets from '../services/Sheets.html';

  export default {
    computed: {
      projects: ({$data, query}) => {
        if ($data) {
          return $data.filter(datum => {
            let byTitle = _.includes(datum.title.toLowerCase(), query.freeText.toLowerCase());
            let byClient = _.includes(datum.client.toLowerCase(), query.freeText.toLowerCase());
            let byCategory = !query.categories.length ? true : _.intersection(datum.categories, query.categories).length;
            let byMedia = !query.media.length ? true : _.intersection(datum.media, query.media).length;
            let byDepartments = !query.departments.length ? true : _.intersection(datum.departments, query.departments).length;
            let byIndustry = !query.industry.length ? true : _.intersection([datum.industry], query.industry).length;
            let byClients = !query.client.length ? true : _.intersection([datum.client], query.client).length;
            let byFreeTags = !query.free.length ? true : _.intersection(datum.free, query.free).length;
            let byRelevant = !query.relevant.length ? true : _.intersection([datum.relevant], query.relevant).length;
            let byCase = !query.case.length ? true : _.intersection([datum.case], query.case).length;
            let byYears = !query.years.length ? true : _.intersection(datum.years, query.years).length;
          
            return (byTitle || byClient) && byCategory && byMedia && byDepartments && byIndustry && byClients && byFreeTags && byRelevant && byCase && byYears;
          })
          .sort((a,b) => {
            // if (!a.to) console.log(a.to);
            const format = (v) => moment(v).format('YYMMDD');

            return format(b.to) - format(a.to);
            // if (a.projectId) {
            //   return +b.projectId - +a.projectId
            // } else {
            //   const format = (v) => moment(v).format('YYMMDD')
            //   return format(b.created_at) - format(a.created_at)
            // }
            })
        }
      },
      projectsCount: ({projects}) => projects ? projects.length : 0,
      projectRange: ({ $page, projectsPerPage, projectsCount }) => {
        if (projectsCount) {
          let start = projectsPerPage * ($page - 1) + 1;
          let end = projectsPerPage * $page;

          if (end > projectsCount) end = projectsCount;

          return [start, end];
        }
      }
    },
    methods: {
      selectProject(selectedProject) {
        this.set({selectedProject});
      },
      closeProjectsDetail() {
        this.set({selectedProject: null});
      }
    },
    data() {
      return {
        projectsPerPage: 12,
        selectedProject: null,
        shareList: [],
        shareListHeight: 0,
        query: {
          freeText: "",
          categories: [],
          media: [],
          departments: [],
          industry: [],
          client: [],
          free: [],
          relevant: [],
          case: [],
          years: []
        },
        searchFields: [
          {
            type: false,
            label: "authors",
            key:'authors',
            detail: "list"
          },
          {
            type: 'fixed',
            label: "media",
            key: "media",
            detail: "list"
          },
          {
            type: 'fixed',
            label: "categories",
            key: "categories",
            detail: "list"
          },
          {
            type: 'fixed',
            label: "departments",
            key: 'departments',
            detail: "list"
          },
          {
            type: 'fixed',
            label: 'industry',
            key: 'industry',
            detail: "single"
          },
          {
            type: 'fixed',
            label: "years",
            key: 'years',
            detail: "list"
          },
          {
            type: 'fixed',
           label: 'relevant',
           key: 'relevant',
           detail: "single"
          },
          {
            type: 'fixed',
            label: 'case',
            key: 'case',
            detail: "single"
          },
          {
            type: "search",
            label: "clients",
            key: "client",
            detail: false
          },
          {
            type: 'search',
            label: "free tags",
            key: "free",
            detail: "list"
          },
        ]
      }
    },
    transitions: {slide},
    components: {
      Header,
      ProjectsSearch,
      ProjectsPagination,
      ProjectsList,
      ProjectDetail,
      ShareList
      // Sheets
    }
  };
</script>
