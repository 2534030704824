import _ from 'lodash';
// const formatLinks = (string) => {
//   let res = string.split('\n').filter(o => o !== '' && o !== '\t');
//   res = res.map(d => {
//     let tmp = d.replaceAll('\n', '').split(': ');
//     return {
//       title: tmp[0],
//       url: tmp[1] ? tmp[1].replaceAll('<', '').replaceAll('>', '').replaceAll(' ', '') : ''
//     }
//   })
//   return res;
// }

const formatLinks = (text) => {
  let formattedText = [];

  if (text) {
    const lines = text.split("\n");
    lines.map((line) => {
      const regex = /\[([^\]]+)\]\(([^)]+)\)/;
      const match = line.match(regex);

      if (match) {
        const name = match[1];
        const link = match[2];

        formattedText.push({
          title: name,
          url: link,
        });
      }
    });
  }

  return formattedText;
}

const prepareDataFromAirtable = data => {
  let res = _.chain(data)
    .filter(o => !o['Uploaded By'])
    .filter(o => o.Title)
    .map(o => {

      const getYears = (o) => {
        let res = []
        const start = o.Start ? +o.Start.split('-')[0] : null;
        const end = o.End ? +o.End.split('-')[0] : null;

        for (let y = start; y <= end; y++) {
          res.push(y)
        }

        return res
      }
      let res = {
        airtableId: o.airtableId,
        id: o.id,
        title: o.Title,
        client: o['Name (from Client)'] ? o['Name (from Client)'][0] : null,
        description: o.Description,
        from: o.Start,
        to: o.End,
        private: o['Name (from Private)'],
        inDir: o['Name (from DIR / INDIR)'],
        clienteDaFatturare: o['Name (from Billing Client)'] ? o['Name (from Billing Client)'][0] : null,
        duration: o['Duration (years)'],
        status: o['Name (from Status)'],
        industry: o['Name (from Industry)'] ? o['Name (from Industry)'][0] : null,
        authors: o['Name (from Authors)'],
        links: o['Project Links'] && o['Project Links'].length ? formatLinks(o['Project Links']) : [],
        categories: o['Name (from Categories)'],
        media: o['Name (from Media)'],
        departments: o['Name (from Departments)'],
        source: o['Name (from Source)'] ? o['Name (from Source)'][0] : null,
        satisfaction: o['Client Satisfaction'],
        note: o.Notes,
        free: o['Name (from Free Tags)'],
        thumbnailUrl: o.Thumbnail ? o.Thumbnail[0].thumbnails.large.url : null,
        relevant: o.Rilevante,
        case: o.Caso,
        years: getYears(o)
      }
      return res
    })

    .value();

  return res;
};

export {
  prepareDataFromAirtable
};
