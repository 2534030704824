
<div class="share-list">
  {#if !shared}
    <div class="header">
      <div><span>{shareList.length}</span> Selected projects</div>
      <div class="header-actions">
        <div class="remove" on:click="set({shareList: []})">remove all</div>
        <div class="button-small {collapsed ? 'button-small_add' : 'button-small_remove'}" on:click="toggleCollapsed()"></div>
      </div>
    </div>
    {#if !collapsed}
      <div class="content" transition:slide>
        <div class="projects">
        {#each shareList as project}
          <div class="project">
            <Thumbnail {project}/>
            <p>{project.title}</p>
          </div>
        {/each}
        </div>
        <div class="actions">
          <TextInput bind:value="title" placeholder="Best projects ever" type="text" label="Title"/>
          <div class="share-button">
            <Button type="button" disabled="{!title}" on:tap="share(id)">Share</Button>
          </div>
        </div>
      </div>
    {/if}
  {:else}
    <div class="disclaimer">
      <div class="disclaimer-content">
        <div class="dida">ready to share</div>
        <div class="disclaimer-title">{title}</div>
        <div class="dida">at this link:</div>
        <div class="disclaimer-link">
          <span ref:link>catalog.thevisualagency.com/client/{id}</span>
          <Button type="button" on:tap="copy()">{copyLabel}</Button>
        </div>
      </div>
      <div class="share-button button-close">
        <Button type="button" on:tap="close()">Close</Button>
      </div>
    </div>
  {/if}
</div>

<style type="text/postcss">
  @import '../lib/css/properties.css';
  .header {
    padding: 0.75rem 1.5rem;
    background: var(--orange);
    color: #fff;
    font-size: .825rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header span {
    color: var(--black);
  }
  .header-actions {
    display: flex;
    align-items: center;
  }
  .remove {
    font-size: 13px;
    text-align: right;
    color: #fff;
    margin-right: 1rem;
    cursor: pointer;
  }
  .content {
    display: flex;
  }
  .projects {
    display: flex;
    padding: .75rem;
    width: 70%;
  }
  .actions {
    width: 30%;
    padding: 0.75rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  .share-button {
    margin: 0.75rem 0;
  }
  .project {
    width: 25%;
    padding: .75rem;
    box-sizing: border-box;
  }
  .disclaimer {
    padding: 0.75rem;
    border-top: 10px solid var(--orange);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .disclaimer-content {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0 2rem;
  }
  .dida {
    font-size: 0.75rem;
  }
  .disclaimer-title {
    width: 100%;
    margin-top: .1rem;
    margin-bottom: 0.75rem;
  }
  .button-copy {
    display: inline-block;
    margin-left: 0.75rem;
  }
  .disclaimer-link {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }
  .disclaimer-link span {
    margin-right: 0.75rem;
  }
  .button-close {
    align-self: center;
  }
</style>

<script>
  import shortid from 'shortid';
  import {slide} from 'svelte-transitions';

  import Thumbnail from '../ui/Thumbnail.html';
  import TextInput from '../ui/TextInput.html';
  import Button from '../ui/Button.html';

  export default { 
    oncreate() {
      this.set({id: shortid.generate()});
    },
    methods: {
      share(id) {
        let { database, user } = this.store.get();
        let {  shareList, title } = this.get();
        const shareListCleaned = [];
        shareList.forEach(o => {
          let tmp = {}
          Object.keys(o).forEach(function (key, index) {
            if (o[key]) {
              tmp = Object.assign({ [key]: o[key] }, tmp)
            }
          });
          shareListCleaned.push(tmp)
        })
        let timestamp = Date.now();
        if (database)
          database.ref('shared/' + id).set({ timestamp, title, shareList: shareListCleaned, user: user.email });
        this.set({shared: true})
      },
      close() {
        this.set({shareList: [], shared:false});
      },
      copy() {
        let content = this.refs.link;
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(content);
        selection.removeAllRanges();
        selection.addRange(range);

        try {
          document.execCommand('copy');
          selection.removeAllRanges();
          this.set({copyLabel: 'Copied!'})
        }
        catch(e) {
          console.log(e);
        }
      },
      toggleCollapsed() {
        let {collapsed} = this.get();
        this.set({collapsed: !collapsed});
      }
    },
    data() {
      return {
        id: '',
        title: '',
        copyLabel: 'Copy',
        shareList: [],
        shared: false,
        collapsed: false
      }
    },
    transitions: {slide},
    components: {
      Thumbnail,
      TextInput,
      Button
    }
  }  
</script>
