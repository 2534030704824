{#if tagSet}
  <li class="tag-set">
    <div class="tag-set-header {tagSetOpen === index ? 'selected' : ''} {tagsSelected.length ? 'active' : ''}" on:click="set({tagSetOpen: index})">
      {tagSet.key} <div class="active-tags {tagsSelected.length ? 'active' : ''}">{tagsSelected.length}</div> 
    </div>
    <div class="tag-set-content {tagSetOpen === index ? 'visible' : ''}">
      <div class="tag-set-content-header">
        <div class="tag-set-content-close" on:click="set({tagSetOpen: null})">close</div>
      </div>
      {#if tagSetValues}
      <div class="tags">
        {#each tagSetValues as tag}
          <Tag :tag bind:tagsSelected />
        {/each}
      </div>
      {/if}
    </div>
  </li>
{/if}

<style type="text/postcss">
  @import '../lib/css/properties.css';

  .tag-set {
    padding: 0;
    position: relative;
  }

  .tag-set-header {
    width: calc(100% - 1px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 64px;
    padding: 0 .75rem;
    border-bottom: 1px solid var(--light-grey);
    cursor: pointer;

    font-size: .825rem;
    font-weight: bold;
    text-transform: capitalize;

    transition: all .25s;
  }
  
  .tag-set:last-of-type .tag-set-header {
    border-bottom: 0;
  }

  .tag-set-header:hover,
  .tag-set-header.selected {
    background-color: var(--super-light-grey);
  }

  .tag-set-header.active {
    color: var(--orange);
  }

  .tag-set-content {
    display: none;
    background-color: var(--white);
    position: absolute;
    right: 100%;
    top: 0;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
    overflow: hidden;
    width: 0;
    transition: width .25s;
  }

  .tag-set-content.visible {
    display: block;
    width: 100%;
    z-index: 400;
  }

  .tag-set-content-header {
    background-color: var(--super-light-grey);
    padding: 0 .5rem;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .tag-set-content-close {
    font-size: .825rem;
    color: var(--dark-grey);
    font-weight: normal;
    cursor: pointer;
  }

  .tags {
    padding: .5rem;
  }

  .active-tags {
    background-color: var(--light-grey);
    color: var(--white);
    border: 0;
    padding: 0;
    border-radius: 20px;
    outline: none;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
  }

  .active-tags.active {
    background-color: var(--orange);
  }
</style>

<script>
  import { setDeep, getDeep } from 'svelte-extras';

  import Tag from '../ui/Tag.html';

  export default {
    oncreate() {
      let { query, tagSet } = this.get()

      this.set({
        tagSetValues: tagSet.values.sort(),
        tagsSelected: query[tagSet.key]
      })
    }, 
    onupdate({changed, current}) {
      if (changed.tagsSelected) {
        this.setDeep(`query.${current.tagSet.key}`, current.tagsSelected);
      }
       if (changed.query) {
        this.set({tagsSelected: current.query[current.tagSet.key]});
      }
    },
    methods: {
      setDeep, getDeep
    },
    data() {
      return {
        selected: false,
        tagsSelected: [],
        tagSetValues: []
      }
    },
    components: {
      Tag
    }
  }
</script>
