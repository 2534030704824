<div class="project-search">
  <div class="text-query">
    <input class="query" type="text" bind:value="query.freeText" placeholder="Search by project name">
    {#if query.freeText}
      <div class="query-reset" on:click="removeQueryFreeText()" >+</div>
    {/if}
  </div>
  {#if tagsSets && tagsSets.length}
    <div class="tags-query">
      <ul class="tags-sets">
        {#each tagsSets as tagSet, index}
          {#if tagSet.type === 'fixed'}
            <TagsSet :tagSet bind:query bind:tagSetOpen :index/>
          {:elseif tagSet.type === 'search'}
            <FilteringTagsField :tagSet bind:query placeholder={tagSet.label} />
          {/if}
        {/each}
      </ul>
    </div>
  {/if}
  {#if activeQuery}
    <div class="reset-button">
      <Button type="button" on:tap="resetQuery()">Remove all filters</Button>
    </div>
    
  {/if}
</div>

<style type="text/postcss">
  @import '../lib/css/properties.css';

  .project-search {
    width: 100%; 
  }

  /* Free text search field */

  .text-query {
    box-sizing: border-box;
    position: relative;
    height: 64px;
    border: 0;
    border-bottom: 1px solid var(--light-grey);
    padding: 0 .75rem;
  }

  .query {
    box-sizing: border-box;
    background-image: url('/assets/images/lens.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 0 50%;
    background-color: transparent;
    border: 0;
    height: 63px;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    color: var(--orange);
  }

  .query:focus {
    outline: none;
  }

  .query::placeholder {
    color: var(--dark-grey);
  }
  .query::placeholder::first-letter {
     text-transform: uppercase;
  }

  .query-reset {
    position: absolute;
    top: 50%;
    right: .75rem;
    margin-top: -10px;

    cursor: pointer;
    background-color: var(--dark-grey);
    color: var(--white);
    border: 0;
    padding: 0;
    border-radius: 20px;
    outline: none;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    transform: rotate(45deg);

    transition: all .25s;
  }

  .query-reset:hover {
    background-color: var(--orange);
  }

  /* Tags/attributes filtering */

  .tags-query {
    border-bottom: 1px solid var(--light-grey);
    /* padding-bottom: .5rem; */
  }
  
  .tags-sets {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .reset-button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem;
    box-sizing: border-box;
  }
</style>

<script>
  import { setDeep } from 'svelte-extras';
  import _ from 'lodash';

  import { resetQuery } from '../../lib/js/utils.js';

  import FilteringTagsField from '../ui/FilteringTagsField.html';
  import TagsSet from '../ui/TagsSet.html';
  import Button from '../ui/Button.html';

  export default {
    computed: {
      tagsSets: ({$data, searchFields}) => {
        if ($data) {
          let result = [];

          _.each(searchFields, field => {
            
            if (field.type) {
              let tags = [];

              _.each($data, d => {
                tags = _.compact(_.concat(tags, d[field.key]));
              });

              field.values = _.uniq(tags);

              result.push(field)
            }
          });

          return result;
        }
      },
      activeQuery: ({query}) => {
        let result = false;
        _.forIn(query, o => {
          if (o.length) result = true;
        });

        return result;
      }
    },
    data() {
      return {
        tagSetOpen: null
      }
    },
    methods: {
      removeQueryFreeText() {
        this.setDeep('query.freeText', '');
      },
      resetQuery,
      setDeep
    },
    components: {
      TagsSet,
      FilteringTagsField,
      Button
    }
  }
</script>
