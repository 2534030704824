<div class="project-pagination">
  {#if projectsCount > 0}
    <div class="serach-counter">Viewing <span class="results-number">{projectsCount}</span> of {$data.length} projects</div>

    <div class="pagination">
        <button on:click="decreasePage()" class="button-small active arrow-left"
          disabled="{$page === 1 ? true : false}"></button>
        {$page} of {pagesCount}
        <button on:click="increasePage()" class="button-small active arrow-right"
          disabled="{pagesCount === $page ? true : false}"></button>
    </div>
  {/if}
</div>

<style type="text/postcss">
  @import '../lib/css/properties.css';

  .project-pagination {
    display: flex;
    padding: 0 1.5rem;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    font-size: .825rem;
    color: var(--dark-grey);
    height: 44px;
  }
  .results-number {
    font-weight: bold;
    color: var(--orange);
  }
  .pagination {
    display: flex;
    align-items: center;
  }
  .arrow-left {
    margin-right: 1rem;
  }
  .arrow-right {
    margin-left: 1rem;
  }
</style>

<script>
  export default {
        onupdate({ changed, current, previous }) {

      if (changed.projectsCount) {
        this.set({
          pagesCount: Math.ceil(current.projectsCount / current.projectsPerPage)
        })

        // set page one only if change project count (when query change)
        if (previous && previous.projectsCount && current.projectsCount !== previous.projectsCount) {
          this.store.set({ page: 1 })
        }
      }
    },
    data() {
      return {
        projectsCount: 0,
        pagesCount: 0
      }
    },
    methods: {
      increasePage: function () {
        let { pagesCount } = this.get();
        const { page } = this.store.get()

        if (page < pagesCount) {
          this.store.set({ page: page + 1 });
        }
      },
      decreasePage: function() {
        let { page } = this.store.get();

        if (page > 1) {
          this.store.set({ page: page - 1 });
        }
      }
    }
  };
</script>
