<div class="sheets">
  {#if isSignedIn}
    <div class="action">
      <Button on:tap="updateProjects()" disabled={isSaving}>{isSaving ? 'updating...' : 'Update projects'}</Button>
    </div>
  {/if}

  {#if isInitialized}
    <div class="action">
      {#if !isSignedIn}
        <Button on:tap="handleAuthClick()" style="display: {isSignedIn ? 'none' : 'block'};">Sign in to update projects</Button>
      {:else}
        <Button on:tap="handleSignoutClick()" style="display: {isSignedIn ? 'block' : 'none'};">Sign out</Button>
      {/if}
    </div>
  {/if}
</div>

<style type="text/postcss">
  @import '../lib/css/properties.css';
  .sheets {
    display: flex;
  }

  .action {
    margin-right: 1rem;
  }

  .action:last-of-type {
    margin-right: 0;
  }
</style>

<script>
  import axios from 'axios/dist/axios';
  import slug from 'speakingurl';

  // Components
  import Button from '../ui/Button.html';

  export default {
    data() {
      return {
        isSignedIn: false,
        isInitialized: false,
        isSaving: false,
        spreadsheetId: '1nwYuXtqOOo-OWz1VbKj7lhdLrdL77ssWZY7iysIhIY8',
        spreadsheetGid: 'Catalog'
      }
    },
    oncreate() {
      this.handleClientLoad();
    },
    methods: {
      handleClientLoad() {
        gapi.load('client:auth2', this.initClient.bind(this));
      },
      /**
       *  Initializes the API client library and sets up sign-in state
       *  listeners.
       */
      initClient() {
        // Client ID and API key from the Developer Console
        const CLIENT_ID = '209314523601-2msol788hs2ca54f2s7l3bqut3t7lbla.apps.googleusercontent.com';
        const API_KEY = 'AIzaSyDtgkNtI6GlyT1GR3mPKGUA0KPHd1hpTt0';

        // Array of API discovery doc URLs for APIs
        const DISCOVERY_DOCS = ["https://sheets.googleapis.com/$discovery/rest?version=v4", "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

        // Authorization scopes required by the API; multiple scopes can be
        // included, separated by spaces.
        const SCOPES = "https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive.readonly";

        gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        }).then(() => {
          // Listen for sign-in state changes.
          gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus.bind(this));

          // Handle the initial sign-in state.
          this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
          this.set({isInitialized: true});
        });
      },
      /**
       *  Called when the signed in status changes, to update the UI
       *  appropriately. After a sign-in, the API is called.
       */
      updateSigninStatus(isSignedIn) {
        this.set({ isSignedIn });
      },
      /**
       *  Sign in the user upon button click.
       */
      handleAuthClick(event) {
        gapi.auth2.getAuthInstance().signIn();
      },
      /**
       *  Sign out the user upon button click.
       */
      handleSignoutClick(event) {
        gapi.auth2.getAuthInstance().signOut();
      },
      updateProjects() {
        const { spreadsheetId, spreadsheetGid } = this.get(); 
        const range = `${spreadsheetGid}!A1:Q`;
        this.set({ isSaving: true });

        gapi.client.sheets.spreadsheets.values.get({
          spreadsheetId,
          range,
        }).then(response => {
          const projects = this.rowsToDocuments(response.result.values);
          this.saveProjects(projects);
        });
      },
      rowsToDocuments(sheet) {
        const head = sheet[0];
        const rows = sheet.slice(1);
        const splitable = ['authors', 'categories', 'departments', 'free', 'media'];
        const deepSplitable = ['links'];
        const mandatoryType = 'title';
        const thumbsBasePath = 'https://interactive.thevisualagency.com/assets/catalog/thumbs';
        const thumbsExt = 'png';

        const split = str => {
          return str
            .split(',')
            .filter(item => item !== '');
        }

        const deepSplit = str => {
          const links = split(str)
            .filter(link => link.trim() !== ';')
            
          return links.length ? 
            links.map(link => { 
              let linkArray = link.split(';');
              return { title: linkArray[0], url: linkArray[1] }
            }) : [];
        }

        const generateThumbURL = (client, title) => {
          return `${thumbsBasePath}/${slug(client)}_${slug(title)}.${thumbsExt}`;
        }

        const generateTimestamp = (year, month) => {
          const { options } = this.store.get('options');
          let parsedMonth = options.months.indexOf(month) + 1;

          if (parsedMonth < 10) {
            parsedMonth = ('0' + parsedMonth).slice(-2);
          }
          return Date.parse(`${year}-${parsedMonth}-01 23:00:00`) / 1000;
        }

        const documents = rows.map((row, i) => {
          return row.map((col, j) => {
            let document;
            const key = head[j];
            const title = key === 'title' ? col : null;
            const client = key === 'client' ? col : null;

            // Checks type and and transform
            // col value accordingly
            if (splitable.indexOf(key.trim()) !== -1) {
              document = { [key]: split(col) };
            } else if (deepSplitable.indexOf(key.trim()) !== -1) {
              document = { [key]: deepSplit(col) };
            } else {
              document = { [key]: col };
            }

            return document;
          })
          // Turn filters arrays in an object
          .reduce((acc, item) => {
            const key = Object.keys(item)[0];
            acc[key] = Object.values(item)[0];
            return acc;
          })
        })
        // Removes rows with empty mandatory type
        .filter(row => mandatoryType in row)
        .filter(row => row[mandatoryType].trim() !== '')
        .map(document => {
          // Add thumb field
          document.thumb = generateThumbURL(document.client, document.title);

          // Add startDateTimestamp and launchDateTimestamp field
          document.startDateTimestamp = generateTimestamp(document.startYear, document.startMonth);
          document.launchDateTimestamp = generateTimestamp(document.launchYear, document.launchMonth);
          return document;
        });

        return documents;
      },
      saveProjects(projects) {
        const { token, functionsUrl } = this.store.get();

        return axios({
          method: 'post',
          url: `${functionsUrl}/api/regenerate/projects`,
          data: JSON.stringify(projects),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          console.log(response);
          this.set({ isSaving: false });
        })
        .catch(err => {
          if (err)
            console.log(err);
            this.set({ isSaving: false });
        });
      }
    },
    components: {
      Button
    }
  }
</script>
