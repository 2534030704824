<div class="login">
  <section class="signin">  
    <header class="header">
      <h1 class="owner">The Visual Agency</h1>
      <h2 class="product">Cata<strong>log</strong></h2>
    </header>

    <form class="form" on:submit="signIn(event)">
      <div class="group">
        <TextInput bind:value="email" label="Email" placeholder="user@mycoolcompany.com" type="email" required=true />
      </div>
      <div class="group">
        <TextInput bind:value="password" label="Password" placeholder="my supersecret password" type="password" required=true />
      </div>
      <div class="group">
        <Button type="submit" disabled={!validCredentials}>Sign In</Button>
      </div>
    </form>

    {#if message} 
      <div class="message">{message}</div>
    {/if}
  </section>
  <section class="background"></section>
</div>

<style type="text/postcss">
  @import '../lib/css/properties.css';

  .login {
    display: flex;
    justify-content: center;
    height: 100vh;
  }

  .signin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    width: 30%;
  }

  .background {
    background-color: var(--orange);
    width: 70%;
    height: 100vh;
  }

  .owner {
    font-size: 1rem;
    font-weight: normal;
    color: var(--orange);
    margin: .25rem 0;
  }

  .product {
    color: var(--black);
    font-weight: 700;
    margin: .25rem 0;
    
  }

  .header {
    width: 60%;
    margin-bottom: 2rem;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .message {
    font-size: .825rem;
    color: var(--orange);
    width: 60%;
  }

  .group {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
</style>

<script>
  import { observe } from 'svelte-extras';

  // Components
  import TextInput from '../ui/TextInput.html';
  import Button from '../ui/Button.html';

  export default {
    data() {
      return {
        email: '',
        password: '',
        message: null
      }
    },
    computed: {
      validCredentials: ({ email, password }) => (email !== '' && password !== '')
    },
    oncreate() {
      const { history, token } = this.store.get();
      
      this.observe('token', token => {
        if (token) {
          history.push('/projects');
        }
      });
    },
    methods: {
      observe,
      signIn: function(event) {
        event.preventDefault();
        const { fb, history } = this.store.get();
        const { email, password } = this.get();

        fb.auth().signInWithEmailAndPassword(email, password)
          .then(response => {
            history.push('/projects');
          })
          .catch(error => {
            this.set({ message: error.message });
          });
      }
    },
    components: {
      TextInput,
      Button
    }
  };
</script>
