<div class="text-input">
  {#if label || required}
    <div class="meta">
      {#if label}
        <label class="label">{label}</label>
      {/if}
      <div class="info">{required ? 'Required' : ''}</div>
    </div>
  {/if}
  {#if type === "email"}
    <input bind:value class="input" placeholder={placeholder} type="email" {required}/>
  {:elseif type === "password"}
    <input bind:value class="input" placeholder={placeholder} type="password" {required}/>
  {:elseif type === "text"}
    <input bind:value class="input" placeholder={placeholder} type="text" {required} on:blur="fire('blur', event)" on:keypress="fire('keypress', event)"/> 
  {/if}
</div>


<style type="text/postcss">
  @import '../lib/css/properties.css';

  .text-input {
    width: 100%;
  }

  .input {
    box-sizing: border-box;
    border: 2px solid var(--light-grey);
    border-radius: 4px;
    color: var(--black);
    font-size: .825rem;
    padding: .5rem;
    transition: border-color .25s;
    width: 100%;
  } 

  input:focus {
    border: 2px solid var(--orange);
    outline: none;
  }

  .input::placeholder {
    color: var(--light-grey);
  }

  input:-webkit-autofill {
    border: 2px solid var(--orange);
    -webkit-text-fill-color: var(--dark-grey);
    -webkit-box-shadow: 0 0 0px 1000px var(--super-light-grey) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: .35rem;
  }

  .label {
    display: block;
    font-size: .825rem;
    color: var(--black);
    font-weight: bold;
    margin-right: 1rem;
    width: 50%;
  }

  .info {
    text-align: right;
    width: 50%;
    font-size: .725rem;
    color: var(--dark-grey);
  }
</style>

<script>
  export default {
    data() {
      return {
        value: '',
        label: null,
        required: false
      }
    }
  }
</script>
