<Route exact path="/" component={Login}></Route>
<Route path="/login" component={Login}></Route>
<Route path="/projects" component={Projects}></Route>
<Route path="/client/:id" component={Client}></Route>
<Route path="/shared" component={SharedLists}></Route>

<style type="text/postcss">
  @import '../lib/css/global.css';
</style>

<script>
  import { observe } from 'svelte-extras';
  import axios from 'axios';
                                                      import Airtable from 'airtable';

  import {prepareDataFromStrapi} from '../func/prepareDataFromStrapi.js'
                                                      import { prepareDataFromAirtable } from '../func/prepareDataFromAirtable.js'
  // Components
  import Route from 'svelte-routing/Route.html';
  import Projects from './pages/Projects.html';
  import Login from './pages/Login.html';
  import Client from './pages/Client.html';
  import SharedLists from './pages/SharedLists.html';

  // Config
  import timestamp from '../config/timestamp.json';

  export default {
    oncreate() {
      const { fb, history } = this.get();

      // TO GO directly
      // history.push('/projects');

      // Set authentication token
      fb.auth().onAuthStateChanged(user => {
        if (user) {
          // Get User token
          user.getIdToken()
            .then(token => {
              this.store.set({token, user});
              this.getData();

              if (!history.location.pathname.includes('/projects') && !history.location.pathname.includes('/client') && !history.location.pathname.includes('/shared'))
                history.push('/projects');
            })
            .catch(error => {
              history.push('/login', error);
            })

        } else {
          // Redirect to login if no user is signed in
          if (!history.location.pathname.includes('/client') && !history.location.pathname.includes('/shared'))
            history.push('/login');
        }
      });

      // set page on startup
      this.store.set({ page: 1 })
    },
    data() {
      return {
        token: null,
        resource: null,
        action: null,
        id: null,
        Projects,
        Login,
        Client,
        SharedLists
      }
    },
    computed: {
      token: ({$token}) => $token 
    },
    components: {
      Route
    },
    methods: {
      download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      },
      getData() {
        // DATA FROM STRAPI - HEROKU

        // axios.get(`https://tva-catalog-3.herokuapp.com/projects?_limit=1000`)
        // .then(response => {
        //   let data = prepareDataFromStrapi(response.data);

        //     // this.download('new.json', JSON.stringify(response.data))

        //   this.store.set({ data });
        //   console.log(data)
        //   })
        // .catch(function (error) {
        //   console.log(error);
        // });


        // DATA FROM AIRTABLE






        let tmpData = [];
          
        var base = new Airtable({ apiKey: 'patTsNSHjS61cmU9V.dd78a922a0a3336abf53cde612d79d2284afa98464c2af257153853ad8691844' }).base('appi0oTvSVXOlH69z');

        base('Projects').select({
          // Selecting the first 3 records in Grid view:
          // maxRecords: 3,
          view: "Grid view"
        }).eachPage(function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record) {
            try {
              tmpData.push(record)
            } catch (err) {
              console.error(err);
            }
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.

          try {
            fetchNextPage();
          } catch (err) {
            console.error(err);
            return
          }
        }, (err) => {
          if (err) { console.error(err); return; }
          let resData = tmpData.map(o => {
            return Object.assign(o.fields, { airtableId: o.id })
          })

          let data = prepareDataFromAirtable(resData);
          // console.log(resData, data)
          this.store.set({ data });
        });
      },
      observe
    }
  }
</script>
